import request from '@/router/axios';
import {businessUrl} from "@/config/env"
export const list = (type,processId,nameOrCode,pageNo,pageSize) => {
    return request({
        url : businessUrl + "post/selectPage",
        method: "post",
        data: {
            type,
            processId,
            pageNo,
            pageSize,
            nameOrCode
        }
    })
}
//编辑
export const edit = (row) => {
    return request({
        url : businessUrl + "post/update",
        method: "put",
        data: {
            ...row
        }
    })
}
//删除
export const remove = (id) => {
    return request({
        url : businessUrl + "post/delete",
        method: "delete",
        params: {
            id
        }
    })
}
export const changeStatus = (row) => {
    return request({
        url : businessUrl + "post/updateStatus",
        method: "put",
        data : {
            id : row.id,
            status : Number(row.status)
        }
    })
}
export const add = (row) => {
    return request({
        url : businessUrl + "post/insert",
        method: "post",
        data : {
            type: 2,
            ...row,
            status: 1
        }
    })
}
//岗位分类
export const typeList = () => {
    return request({
        url : businessUrl + "dict/selectDictByCategoryId",
        method: "get",
        params: {
            categoryId : "70450f251d9746efa91a3a8f7eadc171"
        }
    })
}
//查询岗位
export const getAllPost = () => {
    return request({
        url : businessUrl + "dict/selectDictByCategoryId",
        method: "get",
        params: {
            categoryId : "70450f251d9746efa91a3a8f7eadc171"
        }
    })
}
//查询工序
export const wklnList = () => {
    return request({
        url : businessUrl + "process/selectAll",
        method: "get",
        data: {
        }
    })
}