<template>
  <div>
    <basic-container>
      <avue-crud :option="tableOption"
                 :data="tableData"
                 :page="page"
                 ref="crud"
                 @current-change="currentChange"
                 @size-change="sizeChange"
                 @selection-change="selectionChange"
                 @refresh-change="refreshChange"
                 @row-save="handleSave"
                 @row-update="handleUpdate"
                 @row-del="handleDel">
        <template slot="status" slot-scope="scope">
          <el-switch v-model="scope.row.status" :inactive-value="1" :active-value="0"
                     @change="change(scope.row)">
          </el-switch>
        </template>
        <template slot="menuLeft">
          <importBtn
              :outApi="this.proApis.POSTEXPORT"
              :innerAPI="this.proApis.POSTIMPORT"
              :API="this.proApis.POSTIMPORTTEMPLATE"
              @refresh="refreshData"
          ></importBtn>
        </template>
        <template slot="menuRight">
          <el-select clearable  v-model="type" :placeholder="$t('JOB CLASSIFICATION')" size="small" style="width:100px !important;marginRight: 5px;">
            <el-option v-for="item in listType" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <el-select clearable v-model="processId" :placeholder="$t('BELONGING WORKING PROCEDURE')" size="small" style="width:100px !important;marginRight: 5px;">
            <el-option v-for="item in listWkln" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <el-input clearable v-model="nameOrCode" :placeholder="$t('JOB NAME OR CODE')"
                    style="display:inlie-block;width:200px;marginRight: 5px;" size="small"></el-input>
        </template>
<!--        <template slot="menu">-->
<!--          <el-button type="text" size="mini" icon="el-icon-discover" style="color:red;">禁用</el-button>-->
<!--        </template>-->
      </avue-crud>
    </basic-container>
  </div>
</template>
<script>
import {list, changeStatus, add, edit, remove, typeList, getAllPost, wklnList} from "@/api/tip"
import importBtn from "@/components/importExport/importBtn";
export default {
  components:{
    importBtn
  },
  data() {
      return {
          listWkln: [],
          //分类
          listType: [],
          //工序
          wklnList: [],
          type: "",
          processId: "",
          nameOrCode: "",
          wkln: "",
          status: "",
          page: {
              pageSize: 20,
              pagerCount: 5,
              total: 10,
              pageCurrent: 1
          },
          disabled: true,
          tableData: [],
          tableOption: {
              delBtn: true,
              menuWidth: 200,
              selection: false,
              tip: false,
              columnBtn: false,
              dialogFullscreen: true,
              labelWidth: 120,
              addBtnText: this.$t('ADD'),
              delBtnText: this.$t('DELETE'),
              editBtnText: this.$t('Edit'),
              emptyText: this.$t('No Data'),
              menuTitle: this.$t('OPERATION'),
              column: [{
                  label: this.$t('NAME'),
                  prop: "name",
                  placeholder: this.$t('PLEASE ENTER A NAME'),
                  rules: [{
                      required: true,
                      message: this.$t('PLEASE ENTER A NAME'),
                      trigger: "blur"
                  }]
              }, {
                  label: this.$t('CODE'),
                  hide: true,
                  display: false,
                  prop: "code",
                  rules: [{
                      required: false,
                      message: this.$t('PLEASE ENTER THE CODE'),
                      trigger: "blur"
                  }]
              }, {
                  label: this.$t('JOB CLASSIFICATION'),
                  type: "select",
                  placeholder: this.$t('PLEASE SELECT POSITION CLASSIFICATION'),
                  prop: "type",
                  dicData: [],
                  props: {
                      label: "name",
                      value: "name"
                  },
                  rules: [{
                      required: true,
                      message: this.$t('PLEASE ENTER POSITION CLASSIFICATION'),
                      trigger: "blur"
                  }]
              },
              {
               label: this.$t('BELONGING WORKING PROCEDURE'), 
               display: false,
               prop: "processName"
              }, 
               {
               label: this.$t('BELONGING WORKING PROCEDURE'), 
               placeholder: this.$t('PLEASE SELECT THE OPERATION'),
               type: "select",
               prop: "processId",
               dicData: [],
               props: {
                 label: "name",
                 value: "id"
               },
                  hide: true,
              }, 
              {
                  label: this.$t('BLEACHERS OR NOT'),
                  type: "radio",
                  prop: "isWatcher",
                  dicData: [
                      {
                          label: this.$t('True'),
                          value: 1,
                      },
                      {
                          label: this.$t('False'),
                          value: 0,
                      }
                  ]

              },
              // {
              //  label: "是否主要岗位",
              //  hide: true,
              //  type: "radio",
              //  prop: "q",
              //  dicData: [{
              //    label: "是",
              //    value: 1,
              //  },
              //    {
              //      label: "否",
              //      value: 0,
              //    }]
              //}, 
              {
                  label: this.$t('DO YOU OFTEN WORK DAY SHIFT'), 
                  type: "radio",
                  prop: "isRegularDaily",
                  dicData: [
                      {
                          label: this.$t('True'),
                          value: 1,
                      },
                      {
                          label: this.$t('False'),
                          value: 0,
                      }
                  ]
              },
              {
                  label: this.$t('SALARY ACCOUNTING METHOD'),
                  type: "select",
                  placeholder: this.$t('PLEASE SELECT SALARY ACCOUNTING METHOD'),
                  prop: "wageType",
                  dicData: [{
                      label: this.$t('RECKON BY THE PIECE'),
                      value: 1,
                  },
                  {
                      label: this.$t('RECKON BY THE TOWER'),
                      value: 2,
                  },
                  {
                      label: this.$t('RECKON BY TIME'),
                      value: 3,
                  },
                  {
                      label: this.$t('UNKNOWN'),
                      value: null,
                  }]
              }, {
                  label: this.$t('SORT'),
                  value: 0,
                  type: "number",
                  sortable: true,
                  prop: "sort"
              }]
          }
      }
  },
  created() {
    //岗位
    getAllPost().then(res => {
      this.listType = res.data;
    });
    //工序
    wklnList().then(res => {
     this.listWkln = res.data;
     this.tableOption.column[4].dicData = res.data;
    });
    typeList().then(res => {
      this.listType = res.data.data;
      this.tableOption.column[2].dicData = res.data.data;
    })
    this.list();
  },
  methods: {
    refreshData(){
      this.list();
    },
    currentChange(pageCurrent) {
      this.page.pageCurrent = pageCurrent;
      this.list();
    },
    sizeChange(pageSize) {
      this.page.pageCurrent = 1;
      this.page.pageSize = pageSize;
      this.list();
    },
    handleDel(row) {
        this.$confirm(this.$t('IsDel'), this.$t('Tips'), {
            confirmButtonText: this.$t('Yes'),
            cancelButtonText: this.$t('Cancel'),
            type: 'warning'
      }).then(() => {
        remove(row.id).then((res) => {
             if(res.data.code == "0000"){
                    this.$message({
                      type: "success",
                      message: this.$t('DeleteSuccess'),
                    });
            }
          this.list();
        })
      })
    },
      handleUpdate(row, index, done) {
      edit(row).then((res) => {
           if(res.data.code == "0000"){
                    this.$message({
                      type: "success",
                      message: this.$t('EDIT SUCCEEDED')
                    });
            }
        this.list();
        done();
      })
    },
      handleSave(row, done) {
        add(row).then((res) => {
          if(res.data.code == "0000"){
                this.$message({
                  type: "success",
                  message: this.$t('AddSuccess')
                });
              }
          this.list();
          done();
      })
    },
    change(row) {
      changeStatus(row)
    },
    selectionChange(list) {
      list != "" ? this.disabled = false : this.disabled = true;
    },
    refreshChange() {
      this.list();
    },
    list() {
      list(this.type,this.processId, this.nameOrCode, this.page.pageCurrent, this.page.pageSize).then(res => {
        this.tableData = res.data.data.items;
        this.page.total = res.data.data.total;
      })
    },
    envText: function () {
      return this.env
    }
  }
}
</script>
<style lang="scss" scoped>
// .el-select-dropdown .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
// // padding-left: 10 px;
// }
</style>